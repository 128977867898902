




import { Vue, Component } from 'vue-property-decorator'
import Summary from '@/components/CoinSummary/Summary.vue'

@Component({
  components: {
    Summary,
  },
})
export default class extends Vue {}
