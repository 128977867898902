import { render, staticRenderFns } from "./SummaryDialog.vue?vue&type=template&id=1b0d0172&scoped=true&"
import script from "./SummaryDialog.vue?vue&type=script&lang=ts&"
export * from "./SummaryDialog.vue?vue&type=script&lang=ts&"
import style0 from "./SummaryDialog.vue?vue&type=style&index=0&id=1b0d0172&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b0d0172",
  null
  
)

export default component.exports