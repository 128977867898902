





































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { vxm } from '@/store'

interface DisplayTransaction {
  displayDate: number
  amount: number
  expireIn: string
  tag: string
  name: string
  remark: string
  transferNumber: string
  transferDate: number
  sum?: number
  monthlyIn?: number
  monthlyOut?: number
}

@Component
export default class extends Vue {
  @Prop() readonly subject!: string
  @Prop() readonly username!: string
  @Prop() readonly firstname!: string
  @Prop() readonly nickname!: string

  private get transaction(): DisplayTransaction[] {
    const res: DisplayTransaction[] = []
    for (const i of vxm.transaction.getTransaction) {
      if (i.username === this.username) {
        for (const j of i.walletSummaries) {
          if (j.subject.toUpperCase() === this.subject) {
            for (const k of j.transactions) {
              res.push({
                displayDate: new Date(k.displayDate).getTime(),
                amount: k.amount,
                expireIn: k.expireIn || '-',
                tag: k.tags.join(', '),
                name: k.name || '-',
                remark: k.remark || '-',
                transferNumber: k.transferRef
                  ? k.transferRef.transferNumber
                  : '-',
                transferDate: k.transferRef
                  ? new Date(k.transferRef.transferDate).getTime()
                  : 0,
              })
            }
            break
          }
        }
        break
      }
    }
    return res
      .sort((a, b) => a.displayDate - b.displayDate)
      .reduce<DisplayTransaction[]>((prev, curr) => {
        const lastEl = prev.slice(-1)[0]
        let sum = lastEl?.sum || 0
        let monthlyIn = lastEl?.monthlyIn || 0
        let monthlyOut = lastEl?.monthlyOut || 0
        sum += curr.amount
        const currDate = new Date(curr.displayDate)
        const prevDate = new Date(lastEl?.displayDate)
        if (!lastEl || currDate.getMonth() === prevDate.getMonth()) {
          if (curr.amount > 0) {
            monthlyIn += curr.amount
          } else {
            monthlyOut += curr.amount
          }
        } else {
          monthlyIn = 0
          monthlyOut = 0
        }
        const data = {
          ...curr,
          sum,
          monthlyIn,
          monthlyOut,
        }
        return [...prev, data]
      }, [])
      .reverse()
  }

  private dateToText(dateValue: number) {
    const date = new Date(dateValue)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear() +
      543}`
  }

  private isNextMonth(index: number) {
    const currEl = this.transaction[index]
    const prevEl = this.transaction[index - 1]
    if (currEl && prevEl) {
      const currDate = new Date(currEl.displayDate)
      const prevDate = new Date(prevEl.displayDate)
      return prevDate.getMonth() > currDate.getMonth()
    } else {
      return false
    }
  }

  private monthName(index: number) {
    const nextEl = this.transaction[index + 1]
    if (nextEl) {
      const date = new Date(nextEl.displayDate)
      switch (date.getMonth()) {
        case 0:
          return 'January'
        case 1:
          return 'February'
        case 2:
          return 'March'
        case 3:
          return 'April'
        case 4:
          return 'May'
        case 5:
          return 'June'
        case 6:
          return 'July'
        case 7:
          return 'August'
        case 8:
          return 'September'
        case 9:
          return 'October'
        case 10:
          return 'November'
        case 11:
          return 'December'
        default:
          return ''
      }
    } else {
      return ''
    }
  }
}
