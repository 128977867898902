
















































































import { Vue, Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import SummaryDialog from './SummaryDialog.vue'
import { TransactionName } from '../../types/transaction'

@Component({
  computed: {
    transactions: () => vxm.transaction.getSummaryTransaction,
    totalCoin: () => vxm.transaction.totalCoin,
    loading: () => vxm.transaction.getFetchStatus,
    searchVal: () => vxm.globalSearch.getVal,
  },
  components: {
    SummaryDialog,
  },
})
export default class extends Vue {
  private dialog = false
  private selectedSubj = ''
  private selectedUsername = ''
  private selectedFirstname = ''
  private selectedNickname = ''
  private headers = [
    { text: 'ID', value: 'username' },
    { text: 'Nickname', value: 'nickname' },
    { text: 'Firstname', value: 'firstName' },
    { text: 'Subject', value: 'subject' },
    { text: 'Last Topup', value: 'topup' },
    { text: 'Total', value: 'total' },
    { text: 'Type', value: 'type' },
    {
      text: 'Topup Date',
      value: 'topupDate',
      sort: (a: Date, b: Date) => a.getTime() - b.getTime(),
    },
    { text: 'TF Number', value: 'transferNumber', sortable: false },
    {
      text: 'TF Date',
      value: 'transferDate',
      sort: (a: Date, b: Date) => a.getTime() - b.getTime(),
    },
  ]

  mounted() {
    vxm.transaction.fetchTransaction()
  }

  private changeMargin(num: number) {
    vxm.transaction.updateTransactionMargin(num)
  }

  private showSummary(ev: {
    username: string
    firstName: string
    nickname: string
    subject: string
  }) {
    this.selectedSubj = ev.subject
    this.selectedUsername = ev.username
    this.selectedFirstname = ev.firstName
    this.selectedNickname = ev.nickname
    this.dialog = true
  }

  private chipColor(subj: string) {
    switch (subj) {
      case 'MATH':
        return 'amber accent-2'
      case 'PHYSICS':
        return 'deep-purple lighten-3'
      case 'CHEM':
        return 'blue lighten-3'
      default:
        return 'grey lighten-1'
    }
  }

  private dateStr(date: Date) {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  private coinTypeColor(type: TransactionName) {
    switch (type) {
      case TransactionName.bt:
        return 'blue darken-1'
      case TransactionName.bc:
        return 'teal lighten-3'
      case TransactionName.yc:
        return 'amber darken-2'
      case TransactionName.cv:
        return 'red darken-2'
      case TransactionName.cvn:
        return 'green lighten-2'
      default:
        return ''
    }
  }
}
